var productList = new Vue({
  el: "#product-list-view",
  data: {
    mobileScreen: false,
    catInfo: false,
    LOCK_BODY_SCROLL_CLASS: "overflow-hidden",
    activeFilterUp: false,
    activeFilterDown: false,
    priceFilterKey: "order",
    priceUp: "price",
    priceDown: "-price",
  },
  created() {
    window.addEventListener("resize", this.checkWindowWidth);
    this.mobileScreen = window.innerWidth <= MAX_MOBILE_WIDTH;

    if (document.querySelector("#product-list-view")) {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      if (uri) {
        this.activeFilterUp = params.get(this.priceFilterKey) === this.priceUp;
        this.activeFilterDown =
          params.get(this.priceFilterKey) === this.priceDown;
      }
    }
  },
  mounted() {
    if (this.$refs.popup !== undefined) {
      this.$refs.popup.classList.add("show-popup");
    }
  },
  methods: {
    checkWindowWidth: function () {
      this.mobileScreen = window.innerWidth <= MAX_MOBILE_WIDTH;
    },
    openCatInfo: function () {
      this.catInfo = true;
      document.body.classList.add(this.LOCK_BODY_SCROLL_CLASS_PRODUCT_LIST);
    },
    closeCatInfo: function () {
      this.catInfo = false;
      document.body.classList.remove(this.LOCK_BODY_SCROLL_CLASS_PRODUCT_LIST);
    },
  },
});
