const alertMessages = {
  data() {
    return {
      alertOpen: true,
    };
  },
  methods: {
    closeAlertPopup: function () {
      this.alertOpen = false;
    },
  },
};

var headerVue = new Vue({
  el: "#header",
  components: {
    "alert-messages": alertMessages,
  },
  data: {
    // mobile
    menuOpen: false,
    submenuOpen: false,
    parentCategoryID: null,
    carouselTransition: "next",
    subSubmenuOpen: new Set(),
    subSubmenuHeight: null,
    mobileScreen: false,
    // desktop
    desktopMenuHeight: null,
    desktopMenu: new Set(),
    // search
    showSearch: false,
  },
  created() {
    window.addEventListener("resize", this.checkWindowWidth);
    this.$nextTick(() => {
      this.mobileScreen = window.innerWidth <= MAX_MOBILE_WIDTH;
    });
  },
  mounted() {
    this.$refs.searchPopup.classList.add("show-search");
  },
  methods: {
    openMenu: function () {
      this.menuOpen = true;
    },
    closeMenu: function () {
      this.menuOpen = false;
      this.submenuOpen = false;
    },
    openSubMenu: function (id) {
      this.submenuOpen = true;
      this.parentCategoryID = id;
      this.carouselTransition = "next";
    },
    closeSubMenu: function () {
      this.submenuOpen = false;
      this.parentCategoryID = null;
      this.subSubmenuOpen.clear();
      this.carouselTransition = "prev";
    },
    toggleSubSubmenu: function (id) {
      this.subSubmenuOpen.has(id)
        ? this.subSubmenuOpen.delete(id)
        : (this.subSubmenuOpen.clear(), this.subSubmenuOpen.add(id));
      this.$forceUpdate();
    },
    // Toggle desktop menu
    toggleDesktopMenu: function (id) {
      this.desktopMenu.has(id)
        ? this.desktopMenu.delete(id)
        : (this.desktopMenu.clear(), this.desktopMenu.add(id));
      this.$forceUpdate();
    },
    // Close desktop menu
    closeDesktopMenu: function () {
      this.desktopMenu.clear();
      this.$forceUpdate();
    },
    // Menu transition
    beforeEnterMenu: function (el) {
      el.style.height = 0;
    },
    enterMenu: function (el, done) {
      el.style.height = el.scrollHeight + "px";
      done;
    },
    leaveMenu: function (el, done) {
      el.style.height = 0;
      done;
    },
    // check if mobile menu should be shown
    checkWindowWidth: function () {
      this.mobileScreen = window.innerWidth <= MAX_MOBILE_WIDTH;
    },
    // toggle search
    openSearch: function () {
      this.showSearch = true;
      document.body.classList.add(LOCK_BODY_SCROLL_CLASS);
    },
    closeSearch: function () {
      this.showSearch = false;
      document.body.classList.remove(LOCK_BODY_SCROLL_CLASS);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.checkWindowWidth);
  },
});
