if (
  document.querySelector("#change-password") ||
  document.querySelector("#reset-password")
) {
  var changePassword = new Vue({
    el: ".change-password",
    data: {
      error: "is-invalid",
    },
    mounted() {
      this.$nextTick(() => {
        if (document.querySelector("#error_1_id_new_password2")) {
          document.querySelector("#id_new_password1").classList.add(this.error);
        }
        if (document.querySelector("#error_1_id_confirm_password")) {
          document.querySelector("#id_password").classList.add(this.error);
        }
      });
    },
  });
}
