// swiper for new arrivals
let newArrivals = new Swiper(".new-arrivals", {
  loop: true,
  loopedSlides: 6,
  slidesPerView: "auto",
  centeredSlides: false,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  speed: 500,
  breakpoints: {
    900: {
      centeredSlides: true,
    },
  },
  spaceBetween: 32,
});

// swiper for product gallery
let imgCount = document.querySelector("#img-count");

if (imgCount !== null) {
  // thumbnail
  let productGalleryThumbnail = new Swiper(".product-thumbnail-img", {
    slidesPerView: "auto",
    freeMode: true,
    loopedSlides: imgCount.value, //looped slides should be the same
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  // main
  let productGallery = new Swiper(".product-main-img", {
    loop: true,
    slidesPerView: 1,
    loopedSlides: imgCount.value, //looped slides should be the same
    spaceBetween: 2,
    thumbs: {
      swiper: productGalleryThumbnail,
    },
  });
}
