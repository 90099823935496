var productView = new Vue({
  el: "#product-view",
  data: {
    productQuantity: 1,
    showError: false,
    sumArrow: true,
    restArrow: false,
    emptyQuantity: false,
    lowQuantity: false,
    overStock: false,
    quantityMax: null,
    noStock: false,
  },
  created() {
    if (document.querySelector("#product-quantity") !== null) {
      this.quantityMax = parseInt(
        document.querySelector("#product-quantity").getAttribute("max")
      );
      this.noStock = this.quantityMax <= 0;
    }
  },
  computed: {
    enabledAddToCart: function () {
      return this.productQuantity <= this.quantityMax;
    },
    sumArrow: function () {
      let productQuantity = parseInt(this.productQuantity);
      return (
        (productQuantity < this.quantityMax && productQuantity > 0) ||
        this.productQuantity === ""
      );
    },
    restArrow: function () {
      return this.productQuantity > 1;
    },
  },
  watch: {
    productQuantity: function (newValue) {
      const miValue = parseInt(newValue);

      this.showError =
        miValue > this.quantityMax ||
        miValue < 1 ||
        newValue > this.quantityMax ||
        newValue.toString().match(regExInt) === null;
      this.sumArrow = miValue < this.quantityMax || newValue === "";
      this.restArrow = miValue > 1;
      this.emptyQuantity = newValue.toString().match(regExInt) === null;
      this.lowQuantity = miValue < 1;
      this.overStock = miValue > this.quantityMax;
    },
  },
  methods: {
    sumQuantity: function () {
      let quantity = parseInt(this.productQuantity);
      if (
        this.productQuantity < this.quantityMax &&
        this.productQuantity >= 1 &&
        this.productQuantity.toString().match(regExInt) !== null
      ) {
        quantity += 1;
        this.productQuantity = quantity;
      } else {
        this.productQuantity = 0;
        setTimeout(() => {
          this.productQuantity = 1;
        }, 0);
      }
    },
    restQuantity: function () {
      if (
        this.productQuantity > 1 &&
        this.productQuantity <= this.quantityMax
      ) {
        this.productQuantity -= 1;
      } else if (this.productQuantity > this.quantityMax) {
        this.productQuantity = this.quantityMax;
      }
    },
    // error transition
    beforeEnterError: function (el) {
      el.style.height = 0;
    },
    enterError: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leaveError: function (el) {
      el.style.height = 0;
    },
  },
});
