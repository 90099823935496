var headerVue = new Vue({
  el: "#sign-up-two",
  data: {
    welcome: false,
  },
  created() {
    if (localStorage.getItem("welcome-first-time") === null) {
      this.welcome = true;
    }
  },
  methods: {
    closeWelcome: function () {
      this.welcome = false;
      localStorage.setItem("welcome-first-time", false);
    },
  },
});
