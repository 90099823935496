const productCounter = {
  delimiters: ["[[", "]]"],
  data() {
    return {
      min: 1,
      productQuantity: 1,
      totalTaxes: null,
      subtotalPrice: null,
      itemPrice: null,
      itemPriceTotal: null,
      showError: false,
      sumArrow: true,
      restArrow: false,
      quantityMax: null,
      emptyQuantity: false,
      lowQuantity: false,
      overStock: false,
      apiError: false,
      deleteError: false,
    };
  },
  mounted() {
    if (this.$refs.shoppingCartProductQuantity) {
      this.quantityMax = parseInt(
        this.$refs.shoppingCartProductQuantity.getAttribute("max")
      );
      if (this.quantityMax <= 0) {
        this.deleteProduct(
          this.$refs.shoppingCartProductQuantity.getAttribute("id")
        );
      }
      this.productQuantity = parseInt(
        this.$refs.shoppingCartProductQuantity.getAttribute("value")
      );
    }
    if (this.$refs.shoppingCartProductUnitPrice) {
      this.itemPrice = parseFloat(
        this.$refs.shoppingCartProductUnitPrice.value.split(",").join(".")
      ).toFixed(2);
      this.calcItemPrice(this.productQuantity);
    }
  },
  computed: {
    enabledAddToCart: function () {
      console.log("New Action ")
      return this.productQuantity <= this.quantityMax;
    },
    sumArrow: function () {
      let productQuantity = parseInt(this.productQuantity);
      return (
        (productQuantity < this.quantityMax && productQuantity > 0) ||
        this.productQuantity === ""
      );
    },
    restArrow: function () {
      return this.productQuantity > 1;
    },
  },
  watch: {
    productQuantity: function (newValue) {
      const miValue = parseInt(newValue);

      this.sumArrow = miValue < this.quantityMax || newValue === "";
      this.restArrow = miValue > 1;
      this.emptyQuantity = newValue.toString().match(regExInt) === null;
      this.lowQuantity = miValue < 1;
      this.overStock = miValue > this.quantityMax;
      this.showError = this.emptyQuantity || this.lowQuantity || this.overStock;
      this.$emit("amount-error", this.showError);
    },
  },
  methods: {
    sumQuantity: function (productId) {
      let quantity = parseInt(this.productQuantity);
      if (
        this.productQuantity < this.quantityMax &&
        this.productQuantity >= 1 &&
        this.productQuantity.toString().match(regExInt) !== null
      ) {
        quantity += 1;
        this.productQuantity = quantity;
      } else {
        this.productQuantity = 0;
        setTimeout(() => {
          this.productQuantity = 1;
          this.$nextTick(() => {
            this.calcItemPrice(this.productQuantity);
            this.updateProductPrice(productId, this.productQuantity);
          });
        }, 0);
      }
      if (!this.showError && quantity > 0) {
        this.updateProductPrice(productId, quantity);
        this.calcItemPrice(quantity);
      }
    },
    restQuantity: function (productId) {
      let quantity = parseInt(this.productQuantity);
      if (quantity > 1 && quantity <= this.quantityMax) {
        quantity -= 1;
        this.productQuantity = quantity;
      } else if (quantity > this.quantityMax) {
        quantity = this.quantityMax;
        this.productQuantity = quantity;
        this.$nextTick(() => {
          this.calcItemPrice(quantity);
          this.updateProductPrice(productId, quantity);
        });
      }
      if (!this.showError && quantity > 0) {
        this.updateProductPrice(productId, quantity);
        this.calcItemPrice(quantity);
      }
    },
    calcItemPrice: function (amount) {
      let tempTotalItemPrice = 0;
      tempTotalItemPrice = (this.itemPrice * amount).toFixed(2);
      this.itemPriceTotal = tempTotalItemPrice;
    },
    updateProductPrice(productId, amount, deleteProduct) {
      fetch(window.context.updateProductInCartUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: `product=${productId}&quantity=${amount}`,
      })
        .then((response) => {
          console.log("Here is the response", response.json());
          return response.json();
        })
        .then((data) => {
          if (data.redirect) {
            // this case is when a product is deleted because its quantity is zero and we need
            // to reload the page to show a message
            document.location.reload();
          }
          this.$emit("complete-price", data.order);
        })
        .catch(function (error) {
          if (deleteProduct) {
            this.deleteError = true;
            this.$emit("amount-error", this.deleteError);
          } else {
            this.showError = true;
            this.apiError = true;
            this.$emit("amount-error", this.showError);
          }
        });
    },
    changePriceOnInput: function (productId) {
      let quantity = parseInt(this.productQuantity);
      this.$nextTick(() => {
        if (!this.showError && quantity > 0) {
          this.updateProductPrice(productId, quantity);
          this.calcItemPrice(quantity);
        }
      });
    },
    deleteProduct: function (productId) {
      this.updateProductPrice(productId, 0, true);
    },
  },
};

if (document.querySelector("#shopping-cart")) {
  var nameSelector = "#id_name",
    Shipping_statusSelector = "#id_shipping_status",
    addressSelector = "#id_address",
    phoneNumberSelector = "#id_phone_number",
    zipcodeSelector = "#id_zipcode",
    provinceSelector = "#id_province",
    emailSelector = "#id_email",
    // shippingSelector = "#id_shipping"
    locationSelector = "#id_city";

  var shoppingCart = new Vue({
    el: "#shopping-cart",
    components: {
      "product-counter": productCounter,
    },
    delimiters: ["[[", "]]"],
    data: {
      editForm: false,
      userName: null,
      address: null,
      email: null,
      shipping_status: null,
      // shipping: null,
      province: null,
      location: null,
      postcode: null,
      phone: null,
      saveAddress: false,
      completeTotalPrice: {},
      productError: false,
    },
    created() {
      this.$nextTick(() => {
        if (document.querySelector(nameSelector)) {
          this.userName = document.querySelector(nameSelector).value;
        }
        if (document.querySelector(addressSelector)) {
          this.address = document.querySelector(addressSelector).value;
        }
        if (document.querySelector(locationSelector)) {
          this.location = document.querySelector(locationSelector).value;
        }
        if (document.querySelector(zipcodeSelector)) {
          this.postcode = document.querySelector(zipcodeSelector).value;
        }
        if (document.querySelector(phoneNumberSelector)) {
          this.phone = document.querySelector(phoneNumberSelector).value;
        }
        if (document.querySelector(provinceSelector)) {
          this.province = document.querySelector(provinceSelector).value;
        }
        // if (document.querySelector(shippingSelector)){
        //   this.shipping = document.querySelector(shippingSelector).value;
        // }
        if (document.querySelector(Shipping_statusSelector)) { 
          this.shipping_status = document.querySelector(Shipping_statusSelector).value;
          // var shipping_status =  this.shipping_status;
          // let tempShipping = document.querySelector("#order-shipping");
          // if (shipping_status == "Envío a Península"){
          //   this.completeTotalPrice.shipping = parseFloat(
          //     tempShipping.value.split(",").join(".")
          //   ).toFixed(2);
          // }
          // else if (shipping_status == "Recogida en almacén"){
          //   this.completeTotalPrice.shipping = 0


          // }
        }
        if (document.querySelector(emailSelector)) {
          this.email = document.querySelector(emailSelector).value;
        }
      });

      let tempSubtotal = document.querySelector("#order-subtotal");
      let tempShipping = document.querySelector("#order-shipping");
      let tempTaxes = document.querySelector("#order-taxes");
      let tempDiscount = document.querySelector("#order-discount");
      let tempTotal = document.querySelector("#order-total");

      if (tempSubtotal) {
        this.completeTotalPrice.subtotal = parseFloat(
          tempSubtotal.value.split(",").join(".")
        ).toFixed(2);
      }
      if (tempShipping) {
        this.completeTotalPrice.shipping = parseFloat(
          tempShipping.value.split(",").join(".")
        ).toFixed(2);
      }
      if (tempTaxes) {
        this.completeTotalPrice.taxes = parseFloat(
          tempTaxes.value.split(",").join(".")
        ).toFixed(2);
      }
      if (tempDiscount) {
        this.completeTotalPrice.discount = parseFloat(
          tempDiscount.value.split(",").join(".")
        ).toFixed(2);
      }
      if (tempTotal) {
        this.completeTotalPrice.total = parseFloat(
          tempTotal.value.split(",").join(".")
        ).toFixed(2);
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.cancelEdit();
        this.editForm = this.emptyAddressFields;
      });
      this.checkEmptyError(this.emptyUserNameField, "#user-name-error");
      this.checkEmptyError(this.emptyAddressField, "#address-error");
      this.checkEmptyError(this.emptyLocationField, "#location-error");
      this.checkEmptyError(this.emptyPostcodeField, "#postcode-error");
      this.checkEmptyError(this.emptyProvinceField, "#province-error");
      this.checkEmptyError(this.emptyShipping_statusField, "#shipping_status-error");
      this.checkEmptyError(this.emptyEmailField, "#email-error");
      this.checkEmptyError(this.emptyPhoneField, "#phone-error");
    },
    computed: {
      emptyUserNameField: function () {
        if (this.userName) {
          return this.userName.trim().length === 0;
        } else {
          return document.querySelector(nameSelector).value.trim().length === 0;
        }
      },
      emptyAddressField: function () {
        if (this.address) {
          return this.address.trim().length === 0;
        } else {
          return (
            document.querySelector(addressSelector).value.trim().length === 0
          );
        }
      },
      emptyLocationField: function () {
        if (this.location) {
          return this.location.trim().length === 0;
        } else {
          return (
            document.querySelector(locationSelector).value.trim().length === 0
          );
        }
      },
      emptyPostcodeField: function () {
        if (this.postcode) {
          return this.postcode.trim().length === 0;
        } else {
          return (
            document.querySelector(zipcodeSelector).value.trim().length === 0
          );
        }
      },
      emptyProvinceField: function () {
        if (this.province) {
          return this.province.trim().length === 0;
        } else {
          return (
            document.querySelector(provinceSelector).value.trim().length === 0
          );
        }
      },
      emptyShipping_statusField: function () {
        if (this.shipping_status) {
          console.log(this.shipping_status, 
            this.provice 
            )
          return this.shipping_status.trim().length === 0;
        } else {
          return (
            document.querySelector(Shipping_statusSelector).value.trim().length === 0
          );
        }
      },
      emptyEmailField: function () {
        if (this.email) {
          return this.email.trim().length === 0;
        } else {
          return (
            document.querySelector(emailSelector).value.trim().length === 0
          );
        }
      },

      emptyPhoneField: function () {
        if (this.phone) {
          return this.phone.trim().length === 0;
        } else {
          return (
            document.querySelector(phoneNumberSelector).value.trim().length ===
            0
          );
        }
      },
      emptyAddressFields: function () {
        return (
          this.emptyAddressField ||
          this.emptyLocationField ||
          this.emptyPostcodeField ||
          this.emptyPhoneField ||
          this.emptyUserNameField ||
          this.emptyEmailField ||
          this.emptyProvinceField ||
          this.emptyShipping_statusField
        );
      },
    },
    watch: {
      userName: function () {
        this.saveAddress =
          this.$refs.userName.textContent !==
          document.querySelector(nameSelector).value;
      },
      address: function () {
        this.saveAddress =
          this.$refs.address.textContent !==
          document.querySelector(addressSelector).value;
      },
      location: function () {
        this.saveAddress =
          this.$refs.location.textContent !==
          document.querySelector(locationSelector).value;
      },
      postcode: function () {
        this.saveAddress =
          this.$refs.postcode.textContent !==
          document.querySelector(zipcodeSelector).value;
      },
      phone: function () {
        this.saveAddress =
          this.$refs.phone.textContent !==
          document.querySelector(phoneNumberSelector).value;
      },
      province: function () {
        this.saveAddress =
          this.$refs.province.textContent !==
          document.querySelector(provinceSelector).value;
      },
      shipping_status: function () {
        this.saveAddress =
          this.$refs.shipping_status.textContent !==
          document.querySelector(Shipping_statusSelector).value;
      },
      email: function () {
        this.saveAddress =
          this.$refs.email.textContent !==
          document.querySelector(emailSelector).value;
      },
    },

    methods: {
      openEdit: function () {
        this.editForm = true;
      },
      getUserNameContent: function (event) {
        this.userName = event.target.innerText;
      },
      getAddressContent: function (event) {
        this.address = event.target.innerText;
      },
      getLocationContent: function (event) {
        this.location = event.target.innerText;
      },
      getPostcodeContent: function (event) {
        this.postcode = event.target.innerText;
      },
      getPhoneContent: function (event) {
        this.phone = event.target.innerText;
      },
      getEmailContent: function (event) {
        this.email = event.target.innerText;
      },
      getProvinceContent: function (event) {
        // this.province = event.target.innerText;
      }, 
      onChange: function (e) {
        this.province = e.target.value;
        console.log(this.province);
      },
      getShipping_statusContent: function (event){

      },
      onChangeStatus: function (e){
        this.shipping_status = e.target.value;
        console.log("Shipping status ", this.shipping_status)
        console.log(this.shipping_status)
        var shipping_status =  this.shipping_status;
        let tempShipping = document.querySelector("#order-shipping");
        if (shipping_status == "Envío a Península"){
          this.completeTotalPrice.shipping = parseFloat(
            tempShipping.value.split(",").join(".")
          ).toFixed(2);
        }
        else if (shipping_status == "Recogida en almacén"){
          this.completeTotalPrice.shipping = 0;

          
        }
        console.log("shipping price ", this.completeTotalPrice.shipping)
        console.log("shipping price 1 ", this.order.shipping)
      },

      cancelEdit: function () {
        if (this.userName) {
          this.$refs.userName.textContent = document.querySelector(
            nameSelector
          ).value;
          this.userName = document.querySelector(nameSelector).value;
        }
        if (this.address) {
          this.$refs.address.textContent = document.querySelector(
            addressSelector
          ).value;
          this.address = document.querySelector(addressSelector).value;
        }
        if (this.location) {
          this.$refs.location.textContent = document.querySelector(
            locationSelector
          ).value;
          this.location = document.querySelector(locationSelector).value;
        }
        if (this.postcode) {
          this.$refs.postcode.textContent = document.querySelector(
            zipcodeSelector
          ).value;
          this.postcode = document.querySelector(zipcodeSelector).value;
        }
        if (this.email) {
          this.$refs.email.textContent = document.querySelector(
            emailSelector
          ).value;
          this.email = document.querySelector(emailSelector).value;
        }
        if (this.province) {
          this.$refs.province.textContent = document.querySelector(
            provinceSelector
          ).value;
          this.province = document.querySelector(provinceSelector).value;
        }
        if (this.shipping_status) {
          this.$refs.shipping_status.textContent = document.querySelector(
            Shipping_statusSelector
          ).value;
          this.shipping_status = document.querySelector(Shipping_statusSelector).value;
        }
        if (this.phone) {
          this.$refs.phone.textContent = document.querySelector(phoneNumberSelector).value;
          this.phone = document.querySelector(phoneNumberSelector).value;
        }
        this.editForm = false;
        this.saveAddress = false;
      },
      saveNewAddress: function () {
        console.log("I am here to save the form");
        document.querySelector(
          nameSelector
        ).value = this.$refs.userName.textContent;
        document.querySelector(
          addressSelector
        ).value = this.$refs.address.textContent;
        document.querySelector(
          locationSelector
        ).value = this.$refs.location.textContent;
        document.querySelector(
          zipcodeSelector
        ).value = this.$refs.postcode.textContent;
        document.querySelector(
          phoneNumberSelector
        ).value = this.$refs.phone.textContent;
        document.querySelector(
          emailSelector
        ).value = this.$refs.email.textContent;
        // document.querySelector(
        //   provinceSelector
        // ).value = this.$refs.province.textContent;
        document.querySelector(provinceSelector).value = this.province;
        document.querySelector(Shipping_statusSelector).value = this.shipping_status;
        this.editForm = false;
        this.saveAddress = false;
        console.log("Submit the form now");
        document.getElementById("shopping-cart").submit();
      },
      // buttons transition
      beforeEnterBtn: function (el) {
        el.style.width = 0;
        el.style.opacity = 0;
      },
      enterBtn: function (el) {
        el.style.width = el.scrollWidth + "px";
        el.style.opacity = 1;
      },
      leaveBtn: function (el) {
        el.style.width = 0;
        el.style.opacity = 0;
      },
      // error transition
      beforeEnterError: function (el) {
        el.style.height = 0;
      },
      enterError: function (el) {
        el.style.height = el.scrollHeight + "px";
      },
      leaveError: function (el) {
        el.style.height = 0;
      },
      afterLeave: function (done) {
        done();
      },
      checkEmptyError: function (field, selector) {
        if (field) {
          document.querySelector(selector).style.height =
            document.querySelector(selector).scrollHeight + "px";
        }
      },
      updateCompleteTotalPrice: function (newValue) {
        this.completeTotalPrice = {
          subtotal: parseFloat(newValue.subtotal).toFixed(2),
          total: parseFloat(newValue.total).toFixed(2),
          shipping: parseFloat(newValue.shipping).toFixed(2),
          taxes: parseFloat(newValue.taxes).toFixed(2),
          discount: parseFloat(newValue.discount).toFixed(2),
        };
      },
      updateProductError: function (newValue) {
        this.productError = newValue;
      },
    },
  });
}
