function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
    }
  },
});

const MAX_MOBILE_WIDTH = 900;
const LOCK_BODY_SCROLL_CLASS = "overflow-hidden";
const regExInt = /^[0-9]{1,6}$/;

// cookies
let cookies = () => {
  let cookies = document.querySelector(".cookies");
  let agreeCookies = document.querySelector("#accept-cookies");
  let bottomPosition = "2rem";

  function hideCookies() {
    cookies.style.bottom = "-101%";
    setTimeout(() => cookies.remove(), 750);
  }
  function acceptCookies() {
    hideCookies();
    localStorage.setItem("cookie", true);
  }
  function start() {
    if (localStorage.getItem("cookie") !== null) {
      if (localStorage.getItem("cookie") === "true") {
        acceptCookies();
      }
    } else {
      setTimeout(() => (cookies.style.bottom = bottomPosition), 200);
    }
  }

  agreeCookies.addEventListener("click", acceptCookies, false);

  return {
    start: start,
  };
};

cookies().start();
