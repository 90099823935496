if (document.querySelector("#login")) {
  var logIn = new Vue({
    el: "#login",
    data: {
      show: false,
      passwordInput: document.querySelector("#id_password"),
      error: "is-invalid",
    },
    mounted() {
      this.$nextTick(() => {
        if (document.querySelector("#login-error")) {
          document.querySelector("#id_username").classList.add(this.error);
          document.querySelector("#id_password").classList.add(this.error);
        }
      });
    },
    methods: {
      togglePassword: function () {
        this.show = !this.show;
        document.querySelector("#id_password").type = this.show
          ? "text"
          : "password";
      },
    },
  });
}
